/* src/styles.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playwrite+SK:wght@100..400&family=Playwrite+US+Trad:wght@100..400&display=swap');

@import 'flowbite/dist/flowbite.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {

  @apply transition-colors duration-300 bg-white text-gray-900 dark:bg-white dark:text-gray-700;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-gradient-custom {
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}


.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}
